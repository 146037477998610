// Generated by Framer (92b2253)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Material } from "https://framerusercontent.com/modules/6Ldpz1V0DkD45gXvi67I/PCgBX5d6MdQT7E7nhdXn/Material.js";
import * as localizedValues from "./AUckpsf6l-0.js";
const MaterialFonts = getFonts(Material);

const cycleOrder = ["ElmaCJEaF"];

const serializationHash = "framer-4MV1d"

const variantClassNames = {ElmaCJEaF: "framer-v-19eihhe"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {qMmWsyKjJ: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({click, height, id, width, ...props}) => { return {...props, UzYn_iqh1: click ?? props.UzYn_iqh1} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, UzYn_iqh1, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "ElmaCJEaF", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapyop1na = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (UzYn_iqh1) {const res = await UzYn_iqh1(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-19eihhe", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"ElmaCJEaF"} onTap={onTapyop1na} ref={ref ?? ref1} style={{...style}}><ComponentViewportProvider ><motion.div className={"framer-1nj9pql-container"} layoutDependency={layoutDependency} layoutId={"WqpEHJzEW-container"}><Material color={"rgb(255, 255, 255)"} height={"100%"} iconSearch={"Home"} iconSelection={"Circle"} iconStyle15={"Filled"} iconStyle2={"Filled"} iconStyle7={"Filled"} id={"WqpEHJzEW"} layoutId={"WqpEHJzEW"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></ComponentViewportProvider><ComponentViewportProvider ><motion.div className={"framer-1tqd3hw-container"} layoutDependency={layoutDependency} layoutId={"J6FzCAuzQ-container"}><Material color={"var(--token-bb5a1b5b-5444-416e-944e-7906200c0221, rgb(136, 94, 168))"} height={"100%"} iconSearch={"Home"} iconSelection={"PlayCircle"} iconStyle15={"Filled"} iconStyle2={"Filled"} iconStyle7={"Filled"} id={"J6FzCAuzQ"} layoutId={"J6FzCAuzQ"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-4MV1d.framer-9tl4bi, .framer-4MV1d .framer-9tl4bi { display: block; }", ".framer-4MV1d.framer-19eihhe { cursor: pointer; height: 60px; overflow: visible; position: relative; width: 60px; }", ".framer-4MV1d .framer-1nj9pql-container { flex: none; height: 40px; left: calc(50.00000000000002% - 40px / 2); position: absolute; top: calc(50.00000000000002% - 40px / 2); width: 40px; }", ".framer-4MV1d .framer-1tqd3hw-container { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 60
 * @framerIntrinsicWidth 60
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"UzYn_iqh1":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerAUckpsf6l: React.ComponentType<Props> = withCSS(Component, css, "framer-4MV1d") as typeof Component;
export default FramerAUckpsf6l;

FramerAUckpsf6l.displayName = "PlayButton";

FramerAUckpsf6l.defaultProps = {height: 60, width: 60};

addPropertyControls(FramerAUckpsf6l, {UzYn_iqh1: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerAUckpsf6l, [{explicitInter: true, fonts: []}, ...MaterialFonts], {supportsExplicitInterCodegen: true})